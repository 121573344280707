@import '@we-make-websites/styles-lib/src/config/configuration';
.notFoundHero {
  @include full-bleed;

  &__bannerImageMobile {
    width: 100%;
    height: 100vw;
    object-fit: cover;
  }

  &__bannerImageDesktop {
    height: calc(100vh - 96px);
    object-fit: cover;
  }

  &__bannerContent {
    padding-block-end: var(--spacing-xl);
    padding-block-start: var(--spacing-xl);
    padding-inline-end: var(--gutter-mobile);
    padding-inline-start: var(--gutter-mobile);
  }

  &__bannerSubtitle {
    font-weight: 300 !important;
  }

  &__bannerMessage {
    margin-top: 16px;
    white-space: pre-line;
  }

  &__bannerButton {
    margin-top: var(--spacing-m);
  }

  @include mq($until: l) {
    &__bannerImageDesktop {
      display: none;
    }
    &__bannerMessage {
      font-weight: 300;
    }
  }

  @include mq($from: l) {
    height: calc(100vh - 96px);
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;

    &__bannerImageMobile {
      display: none;
    }

    &__bannerImageDesktop {
      width: 100%;
      height: inherit;
    }

    &__bannerContent {
      padding: 0 50px;
      position: absolute;
      width: 63%;
    }

    &__bannerButton {
      margin-top: var(--spacing-l);
    }
  }

  @include mq($from: xl) {
    height: calc(100vh - 120px);

    &__bannerContent {
      padding: 0 163px;
      position: absolute;
      width: 70%;
    }

    &__bannerImageDesktop {
      height: calc(100vh - 120px);
    }
  }

  @media (min-width: 1680px) {
    height: calc(100vh - 120px);

    &__bannerContent {
      padding: 0 20vw;
      position: absolute;
      width: 78%;
    }

    &__bannerImageDesktop {
      height: calc(100vh - 120px);
    }
  }
}
